import React, { ReactNode } from 'react';
import cn from 'classnames';

type ButtonType = 'Black' | 'White';
// type ButtonSize = 'small' | 'normal';

export interface ButtonPublicProps {
  children?: ReactNode;
  className?: string;
  containerClassName?: string;
  type?: ButtonType;
  working?: boolean;
  noBorder?: boolean;
  workingLabelName?: string;
  onClick: () => void;
  role?: string;
  buttonSize?: string;
  isDisabled?: boolean;
  actionCompleted?: boolean;
}

export function ButtonPublic({
  children,
  className = '',
  containerClassName,
  workingLabelName,
  onClick,
  working = false,
  noBorder,
  type = 'Black',
  role = 'button',
  buttonSize,
  isDisabled,
  actionCompleted,
}: ButtonPublicProps) {
  const clsNames = cn(
    'group dark:border-gray-700/50 text-sm font-medium outline-red-500 rounded-full transform duration-150 ease-linear flex justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed sm:hover:scale-102 hover:-translate-y-0.5 hover:shadow-lg',
    {
      'border border-transparent shadow-sm bg-black dark:bg-gray-800 w-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-800 focus:ring-red-600 ':
        type === 'Black',
    },
    {
      'text-gray-900 border border-gray-300 bg-white w-full hover:bg-black hover:text-white dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700/50':
        type === 'White',
    },
    {
      'border-none border-none shadow-lg': noBorder === true,
    },
    {
      'px-2 py-1 shadow-lg h-8': buttonSize == 'small',
    },
    {
      'px-4 py-2 shadow-lg h-12': buttonSize != 'small',
    },
    { 'cursor-not-allowed': !!isDisabled },
    className
  );

  return (
    <div className={cn('flex-shrink-0', containerClassName)}>
      <button
        onClick={() => !working && onClick()}
        type="button"
        role={role}
        className={clsNames}
        data-testid={`btn-${role}`}
        disabled={isDisabled}
      >
        {!working && <>{children}</>}
        {!!working && (
          <div className="flex flex-row gap-1 items-center">
            <div className="w-8 flex items-center flex-row">
              <img
                alt="loader"
                src="/img/svg-loaders/puff.svg"
                className="mx-2 w-6 h-6 absolute opacity-100 group-hover:opacity-0"
              />
              <img
                alt="loader"
                src="/img/svg-loaders/puff-dark.svg"
                className="mx-2 w-6 h-6 absolute opacity-0 group-hover:opacity-100"
              />
            </div>
            {workingLabelName && `${workingLabelName}`}{' '}
            {!workingLabelName && `Saving`}
          </div>
        )}
      </button>
    </div>
  );
}
