import Link from 'next/link';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import { useCreator } from '#lib/hooks/useCreator';
import { Tooltip } from '@nextui-org/react';
import {
  Cog8ToothIcon,
  TagIcon,
  UserIcon,
  WalletIcon,
} from '@heroicons/react/24/solid';
export interface UserProfileMenuProps {
  userPhoto?: string | null;
  userName?: string | null;
  userId?: string | null;
}

export function UserProfileMenu({
  userPhoto,
  userName,
  userId,
}: UserProfileMenuProps) {
  const { creator, loading } = useCreator({ creatorId: userId });
  const { push } = useRouter();
  const renderCreatorView = () => {
    return (
      <div className="flex flex-col gap-0 text-base w-44 font-medium  ">
        <div className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-200 px-3 py-2 rounded-lg my-1">
          <Link href={`/profile/`}>
            <a className="flex flex-row items-center gap-3 w-full">
              <UserIcon className="w-5 h-5 text-gray-600 dark:text-gray-400" />
              Profile
            </a>
          </Link>
        </div>
        <div className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-200 px-3 py-2 rounded-lg my-1">
          <Link href={`/profile?tab=owned`}>
            <a className="flex flex-row items-center gap-3 w-full justify-between">
              <span className="flex flex-row items-center gap-3 w-full">
                <WalletIcon className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                Collected{' '}
              </span>
              <div className="font-bold text-sky-500 rounded-full flex items-center justify-center px-2">
                {creator.assetCount && creator.assetCount > 0
                  ? `${creator.assetCount}`
                  : ''}
              </div>
            </a>
          </Link>
        </div>
        <div className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-200/50 p-3 rounded-lg my-1">
          <Link href={`/profile?tab=listed`}>
            <a className="flex flex-row items-center gap-3 w-full justify-between">
              <span className="flex flex-row items-center gap-3 w-full">
                <TagIcon className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                For Sale{' '}
              </span>
              <div className="font-bold text-sky-500 rounded-full  flex items-center justify-center px-2">
                {creator.listingCount && creator.listingCount > 0
                  ? `${creator.listingCount}`
                  : ''}
              </div>
            </a>
          </Link>
        </div>
        <div className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-200  px-3 py-2 rounded-lg my-1">
          {' '}
          <Link href={`/account`}>
            <a className="flex flex-row items-center w-full gap-2 ">
              <span className="flex flex-row gap-3 items-center">
                <Cog8ToothIcon className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                Settings
              </span>

              {!loading && !creator.displayName && (
                <span className="bg-blue-400 rounded-full text-xs px-3 pt-1 pb-1.5 flex-items-center text-white font-light border border-blue-300/50 shadow-md absolute right-1">
                  setup
                </span>
              )}
            </a>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <Tooltip content={renderCreatorView()} placement={'bottom'}>
      <Link href={'/profile'}>
        <a className="relative flex text-sm rounded-full hover:-translate-y-1 duration-200 ">
          {!loading && !creator.displayName && (
            <div className="bg-blue-400 h-3 w-3 rounded-full absolute z-10 right-0 top-0 shadow-sm"></div>
          )}
          <div className=" border border-gray-300 dark:border-gray-700 rounded-full overflow-hidden w-11 h-11">
            <img
              className="w-full h-full object-cover shadow-lg rounded-full"
              src={userPhoto || 'https://www.gravatar.com/avatar/?d=mp'}
              alt={`User Profile Picture (${userName || 'Name not provided'})`}
            />
          </div>
        </a>
      </Link>
    </Tooltip>
    // <Menu as="div" className="relative">
    //   {({ open }) => (
    //     <>
    //       <div>
    //         <Menu.Button className="bg-black flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black rounded-full">
    //           <span className="sr-only">Open user menu</span>
    //           <img
    //             className="h-10 w-10 object-cover ring-2 ring-white dark:ring-gray-700 shadow-lg rounded-full pixelated"
    //             src={userPhoto || 'https://www.gravatar.com/avatar/?d=mp'}
    //             alt={`User Profile Picture (${
    //               userName || 'Name not provided'
    //             })`}
    //           />
    //         </Menu.Button>
    //       </div>
    //       <Transition
    //         show={open}
    //         as={Fragment}
    //         enter="transition ease-out duration-200"
    //         enterFrom="transform opacity-0 scale-95"
    //         enterTo="transform opacity-100 scale-100"
    //         leave="transition ease-in duration-75"
    //         leaveFrom="transform opacity-100 scale-100"
    //         leaveTo="transform opacity-0 scale-95"
    //       >
    //         <Menu.Items
    //           static
    //           className="z-50 origin-top-right absolute right-0  w-72 rounded-md shadow-lg py-4 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
    //         >
    //           <Menu.Item>
    //             {({ active }) => (
    //               <Linkgit pu
    //                 href={
    //                   !!creator.displayName
    //                     ? `/profile/${userName}`
    //                     : `/account`
    //                 }
    //               >
    //                 <a
    //                   className={classNames(
    //                     active ? 'bg-primary-100' : '',
    //                     'pl-6 pr-4 py-3 text-base text-primary-900 flex flex-row items-center gap-4 hover:bg-primary-100'
    //                   )}
    //                 >
    //                   <div className="w-12">
    //                     <img
    //                       src={
    //                         userPhoto || 'https://www.gravatar.com/avatar/?d=mp'
    //                       }
    //                       alt="Profile Photo"
    //                       className="w-10 h-10 object-cover rounded-full pixelated shadow-md"
    //                     />
    //                   </div>
    //                   {!!creator.displayName && (
    //                     <div className="flex-1">Profile</div>
    //                   )}

    //                   {!creator.displayName && (
    //                     <div className="flex-1"> Create Profile</div>
    //                   )}
    //                   <ChevronRightIcon className="h-6 w-6" />
    //                 </a>
    //               </Link>
    //             )}
    //           </Menu.Item>
    //           {!!claims?.creator && (
    //             <Menu.Item>
    //               {({ active }) => (
    //                 <Link href="/create" passHref>
    //                   <a
    //                     role="button"
    //                     className={classNames(
    //                       active ? 'bg-primary-100' : '',
    //                       'pl-6 pr-4 py-3 text-base text-primary-900 hover:bg-primary-100  flex flex-row items-center gap-4 '
    //                     )}
    //                   >
    //                     <div className="w-9">
    //                       <CollectionIcon className="h-6 w-6 text-gray-700" />
    //                     </div>

    //                     <div className="flex-1">Collections</div>

    //                     <ChevronRightIcon className="h-6 w-6" />
    //                   </a>
    //                 </Link>
    //               )}
    //             </Menu.Item>
    //           )}
    //           {!claims?.creator && (
    //             <Menu.Item>
    //               {({ active }) => (
    //                 <Link href="/create/onboard" passHref>
    //                   <a
    //                     role="button"
    //                     className={classNames(
    //                       active ? 'bg-primary-100' : '',
    //                       'pl-6 pr-4 py-3 text-base text-primary-900 hover:bg-primary-100  flex flex-row items-center gap-4 '
    //                     )}
    //                   >
    //                     <div className="w-9">
    //                       <SparklesIcon className="h-6 w-6 text-red-400" />
    //                     </div>

    //                     <div className="flex-1">Become a Creator</div>

    //                     <ChevronRightIcon className="h-6 w-6" />
    //                   </a>
    //                 </Link>
    //               )}
    //             </Menu.Item>
    //           )}

    //           <Menu.Item>
    //             {({ active }) => (
    //               <Link href="https://intercom.help/dropspot/en" passHref>
    //                 <a
    //                   role="button"
    //                   target={'_blank'}
    //                   className={classNames(
    //                     active ? 'bg-primary-100' : '',
    //                     'pl-6 pr-4 py-3 text-base text-primary-900 hover:bg-primary-100  flex flex-row items-center gap-4 '
    //                   )}
    //                 >
    //                   <div className="w-9">
    //                     <AcademicCapIcon className="h-6 w-6 text-gray-700" />
    //                   </div>

    //                   <div className="flex-1">Learn</div>

    //                   <ChevronRightIcon className="h-6 w-6" />
    //                 </a>
    //               </Link>
    //             )}
    //           </Menu.Item>
    //           <Menu.Item>
    //             {({ active }) => (
    //               <a
    //                 role="button"
    //                 onClick={() => {
    //                   console.log('Sign out clicked');
    //                   signOut()
    //                     .then(() => push('/'))
    //                     .catch((err) => console.error(err.message, err.stack));
    //                 }}
    //                 className={classNames(
    //                   active ? 'bg-primary-100' : '',
    //                   'pl-6 pr-4 py-3 text-base text-primary-900 flex flex-row items-center gap-4 cursor-pointer'
    //                 )}
    //               >
    //                 <div className="w-9">
    //                   <LogoutIcon className="h-6 w-6 text-gray-700" />
    //                 </div>
    //                 <div className="flex-1">Sign Out</div>

    //                 <ChevronRightIcon className="h-6 w-6" />
    //               </a>
    //             )}
    //           </Menu.Item>
    //         </Menu.Items>
    //       </Transition>
    //     </>
    //   )}
    // </Menu>
  );
}
