type DropspotMarketErrorTypes =
  | 'COIN_SELECTION'
  | 'INTERNAL'
  | 'BUILD'
  | 'SIGN'
  | 'SEND'
  | 'LISTING'
  | 'CLAIM';
type DropspotMarketErrorType = {
  type: DropspotMarketErrorTypes;
  code: number;
  info: string;
};

export type APIError = {
  code: number;
  info: string;
};

export class DropspotMarketError extends Error {
  type: DropspotMarketErrorTypes;
  code: number;
  info: string;
  title: string;
  message: string;
  autoClose: boolean;
  report: boolean;
  errorId?: string;
  sourceError?: Error | APIError;

  constructor(error: DropspotMarketErrorType, source?: Error | APIError) {
    super(error.info);
    this.type = error.type;
    this.code = error.code;
    this.info = error.info;
    this.sourceError = source;

    if (error.type === 'CLAIM') {
      this.title = 'Incorrect Claim Code';
      this.message = 'Please check your claim code and try again.';
      this.autoClose = false;
      this.report = false;
      this.info = error.info;
      return;
    }

    if (error.type === 'LISTING') {
      const listingErrors = ListingErrors[error.code];

      if (!!listingErrors) {
        this.title = listingErrors.title;
        this.message = listingErrors.message;
        this.autoClose = listingErrors.autoClose;
        this.report = listingErrors.report;
        return;
      }

      this.title = 'Unknown Listing Error';
      this.message = `Error in creation of this transaction (${error.info})`;
      this.autoClose = true;
      this.report = true;
      return;
    }

    if (error.type === 'INTERNAL') {
      const internalError = InternalErrors[error.code];

      if (!!internalError) {
        this.title = internalError.title;
        this.message = internalError.message;
        this.autoClose = internalError.autoClose;
        this.report = internalError.report;
        return;
      }

      this.title = 'Unknown Internal Error';
      this.message = `Error in creation of this transaction (${error.info})`;
      this.autoClose = true;
      this.report = true;
      return;
    }

    if (error.type === 'COIN_SELECTION') {
      const errObj = CoinSelectionErrors[error.code];
      if (!!errObj) {
        this.title = errObj.title;
        this.message = errObj.message;
        this.autoClose = errObj.autoClose;
        this.report = errObj.report;
        return;
      }

      this.title = 'Unknown Coin Selection Error';
      this.message = `Error in creation of this transaction (${error.info})`;
      this.autoClose = true;
      this.report = true;
      return;
    }

    if (error.type === 'SEND') {
      this.title = SendErrors[error.code].title;
      this.message = SendErrors[error.code].message;
      this.autoClose = SendErrors[error.code].autoClose;
      this.report = SendErrors[error.code].report;
      return;
    }

    if (error.type === 'SIGN') {
      const signError = SignErrors[error.code];

      if (!signError) {
        this.title = 'Unknown Sign Error';
        this.message = `Error in creation of this transaction (${error.info})`;
        this.autoClose = true;
        this.report = true;
        return;
      }

      this.title = SignErrors[error.code].title;
      this.message = SignErrors[error.code].message;
      this.autoClose = SignErrors[error.code].autoClose;
      this.report = SignErrors[error.code].report;
      return;
    }

    if (!!APIErrors[error.code]) {
      this.title = APIErrors[error.code].title;
      this.message = APIErrors[error.code].message;
      this.autoClose = APIErrors[error.code].autoClose;
      this.report = APIErrors[error.code].report;
      return;
    }

    this.title = 'Unknown error';
    this.message = error.info;
    this.autoClose = false;
    this.report = true;
  }
}

export enum ListingErrorCode {
  BelowMinPrice = -1,
  NoWalletKeyHash = -2,
}

export enum APIErrorCode {
  InvalidRequest = -1,
  InternalError = -2,
  Refused = -3,
  AccountChange = -4,
}
export enum TxSendErrorCode {
  Refused = 1,
  Failure = 2,
}
export enum TxSignError {
  ProofGeneration = 1,
  UserDeclined = 2,
}

type ErrorMessages = {
  title: string;
  message: string;
  autoClose: boolean;
  report: boolean;
};

export enum CoinSelectionErrorCode {
  INPUT_LIMIT_EXCEEDED = 901,
  MIN_UTXO_ERROR,
  INPUTS_EXHAUSTED,
  PP_NOT_SET,
  INPUTS_LOCKED
}

const ListingErrors: { [key in number]: ErrorMessages } = {
  [ListingErrorCode.BelowMinPrice]: {
    title: 'Below Min Price', // exceeded
    message: 'This is below the minimum price of 50 ADA.',
    autoClose: false,
    report: false,
  },
};

const CoinSelectionErrors: { [key in number]: ErrorMessages } = {
  [CoinSelectionErrorCode.INPUT_LIMIT_EXCEEDED]: {
    title: 'Input limit', // exceeded
    message:
      'You have exceeded the maximum number of inputs allowed for this transaction. Please start a chat with us to resolve.',
    autoClose: false,
    report: false,
  },
  [CoinSelectionErrorCode.MIN_UTXO_ERROR]: {
    title: 'Wallet UTXO',
    message:
      'You have not selected enough UTXOs to cover the minimum required amount. Please start a chat with us to resolve.',
    autoClose: false,
    report: false,
  },
  [CoinSelectionErrorCode.INPUTS_EXHAUSTED]: {
    title: 'Insufficient Funds',
    message: 'Not enough funds in your wallet to cover the transaction. ',
    autoClose: false,

    report: false,
  },
  [CoinSelectionErrorCode.PP_NOT_SET]: {
    title: 'Protocol Parameters not set',
    message: 'This is a developer Error. Please report this to the developer.',
    autoClose: false,
    report: true,
  },
};

export enum InternalErrorCode {
  TxMaxSize = 1,
  NO_COLLATERAL,
  MissingDatum,
  VALIDITY_START_INTERVAL_NOT_MET,
  WALLET_NOT_SET,
  REDEEMER_EX_UNIT_NOT_FOUND,
  UNEXPECTED,
}
const InternalErrors: { [key: number]: ErrorMessages } = {
  [InternalErrorCode.TxMaxSize]: {
    title: 'Transaction too large',
    message:
      'The created transaction is too large to be sent. Please contract Dropspot Support for assistance.',
    autoClose: false,
    report: true,
  },
  [InternalErrorCode.REDEEMER_EX_UNIT_NOT_FOUND]: {
    title: 'Redeemer ExUnit not found',
    message: 'Redeemer ExUnit not found.',
    autoClose: false,
    report: true,
  },
  [InternalErrorCode.NO_COLLATERAL]: {
    title: 'Collateral Not Set',
    message:
      'Please setup collateral in your wallet to complete this transaction.',
    autoClose: false,
    report: false,
  },
  [InternalErrorCode.MissingDatum]: {
    title: 'Datum Missing',
    message: 'Internal Error - Missing Datum on Script Transaction',
    autoClose: false,
    report: true,
  },
  [InternalErrorCode.VALIDITY_START_INTERVAL_NOT_MET]: {
    title: 'Sale is not yet started',
    message: 'Sale has not yet started. Please wait for the sale to start.',
    autoClose: false,
    report: false,
  },
  [InternalErrorCode.WALLET_NOT_SET]: {
    title: 'Wallet not set',
    message: 'Wallet is not set. Perhaps refresh the page and try again.',
    autoClose: false,
    report: true,
  },
};

const APIErrors: { [key: number]: ErrorMessages } = {
  [APIErrorCode.InvalidRequest]: {
    title: 'Invalid Inputs',
    message: 'Inputs do not conform to this spec or are otherwise invalid.',
    autoClose: false,
    report: true,
  },
  [APIErrorCode.InternalError]: {
    title: 'Internal Error',
    message:
      'An error occurred during execution of this API call. Please start a chat with us to resolve.',
    autoClose: false,
    report: true,
  },
  [APIErrorCode.Refused]: {
    title: 'Refused',
    message:
      'The request was refused due to lack of access - e.g. wallet disconnects. Please start a chat with us to resolve.',
    autoClose: false,
    report: true,
  },
  [APIErrorCode.AccountChange]: {
    title: 'Account Change',
    message:
      'The account has changed. The dApp should call wallet.enable() to reestablish connection to the new account. The wallet should not ask for confirmation as the user was the one who initiated the account change in the first place.',
    autoClose: false,
    report: true,
  },
};

const SendErrors: { [key: number]: ErrorMessages } = {
  [TxSendErrorCode.Refused]: {
    title: 'Refused',
    message:
      'Wallet refuses to send the transaction (could be rate limiting). Please start a chat with us to resolve.',
    autoClose: false,
    report: true,
  },
  [TxSendErrorCode.Failure]: {
    title: 'Oops, something went wrong',
    message:
      'Looks like something went wrong on our end.  Please try again in a few minutes. If the issue persists, please contact us to help you out.',
    autoClose: false,
    report: true,
  },
};

const SignErrors: { [key: number]: ErrorMessages } = {
  [TxSignError.ProofGeneration]: {
    title: 'Proof Generation',
    message:
      'User has accepted the transaction sign, but the wallet was unable to sign the transaction (e.g. not having some of the private keys)',
    autoClose: false,
    report: true,
  },
  [TxSignError.UserDeclined]: {
    title: 'Cancelled',
    message: 'This transaction has been cancelled.',
    autoClose: false,
    report: false,
  },
};
