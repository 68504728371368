import { getCreatorListener } from '#lib/firestore';
import { Creator, CreatorNotificationPreferences } from '#types/index';
import { useEffect, useState } from 'react';

type useCreatorProps = {
  creatorId?: string | null | undefined;
  ssrCreator?: Creator;
};

export const useCreator = ({ creatorId, ssrCreator }: useCreatorProps) => {
  const [creator, setCreator] = useState<Creator>(
    ssrCreator || ({} as Creator)
  );
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!creatorId) {
      setLoading(false);
      return;
    }
    return getCreatorListener(creatorId, (data) => {
      data && setCreator(data);
      setLoading(false);
    });
  }, [creatorId]);

  return { creator, setCreator, loading };
};

import { getNotificationPreferencesListener } from '#lib/firestore';

type useCreatorPrefsProps = {
  creatorId: string;
};

export const useCreatorNotificationPreferences = ({
  creatorId,
}: useCreatorPrefsProps) => {
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState<
    CreatorNotificationPreferences | undefined
  >();

  useEffect(() => {
    setLoading(true);
    return getNotificationPreferencesListener(creatorId, (data) => {
      setLoading(false);
      if (!data) {
        setPreferences({
          creatorId: creatorId,
          twitter: false,
          sms: false,
          discord: false,
          notifyOnOffer: false,
          notifyOnSale: false,
        } as CreatorNotificationPreferences);
      } else {
        setPreferences(data);
      }
    }); // Return the result of this so that we can clean up if / when we need to.
  }, [creatorId]);

  return { preferences, setPreferences, loading };
};
