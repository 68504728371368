import Loader from '#lib/Loader';

import {
  getMintRequestForPolicy,
  getOfferMetadata,
  saveOfferMetadata,
} from '#lib/firestore';

import type {
  TransactionBuilder,
  TransactionUnspentOutput,
  TransactionOutputs,
  BaseAddress,
  Value,
  PlutusData,
  PlutusList,
  Address,
  Transaction,
  TransactionBuilderConfig,
  Redeemers,
  TransactionInputs,
  PlutusScript,
  PlutusWitness,
} from '@emurgo/cardano-serialization-lib-browser';

type Royalty777 = {
  addr: string;
  rate: string;
  pct?: string;
};
type Extended777 = ({ tag: string } & Royalty777)[];

import getAbsoluteURL from '../getAbsoluteUrl';
import type { components } from '@blockfrost/blockfrost-js/lib/types/OpenApi';
import { TimeToSlotResponse } from 'pages/api/blockfrost/timeToSlot/[time]';
import { Cardano } from '#types/cardano';
import constants from '#lib/constants';
import { Contract } from '#types/index';
import {
  DropspotMarketError,
  APIError,
  InternalErrorCode,
  ListingErrorCode,
  CoinSelectionErrorCode,
} from './DropspotMarketError';

export type TradeUtxo = {
  lovelace: string;
  datum: PlutusData | null;
  dropspotAddress: string;
  policy: string;
  token: string;
  utxo: TransactionUnspentOutput;
  metadata?: Record<number | '_uid', string> &
    Record<'_metadata', OFFER_DATUM_TYPE>;
};
type DisAmount = DisbType & { amount: number };

export type ProtocolParams = components['schemas']['epoch_param_content'];

export type OFFER_DATUM_TYPE = {
  ownerAddress: Uint8Array;
  price: number;
  policy: string;
  assetName: string;
  startDatePOSIX: string;
};

export type OfferOptions = {
  disbursements?: RoyaltyMetadata[];
  policy: string;
  assetName: string;
  askingPrice: number;
  assetId: string;
};

export type RelistOptions = {
  utxo: TradeUtxo;
  startDate?: number;
  disbursements?: RoyaltyMetadata[];
  askingPrice: number;
  assetId: string;
};

type ProtocolParamsType = {
  epoch: number;
  min_fee_a: number;
  min_fee_b: number;
  max_block_size: number;
  max_tx_size: number;
  max_block_header_size: number;
  key_deposit: string;
  pool_deposit: string;
  e_max: number;
  n_opt: number;
  a0: number;
  rho: number;
  tau: number;
  decentralisation_param: number;
  extra_entropy: null;
  protocol_major_ver: number;
  protocol_minor_ver: number;
  min_utxo: string;
  min_pool_cost: string;
  nonce: string;
  price_mem: number;
  price_step: number;
  max_tx_ex_mem: string;
  max_tx_ex_steps: string;
  max_block_ex_mem: string;
  max_block_ex_steps: string;
  max_val_size: string;
  collateral_percent: number;
  max_collateral_inputs: number;
  coins_per_utxo_word: string;
  coins_per_utxo_size: string;
  era: 'Alonzo' | 'Vasil';
};

interface RoyaltyMetadata {
  pct?: string;
  addr: string | string[];
  rate: string;
}
const MAX_ATTEMPTS = 100;
const DATUM_LABEL = 409;
const ADDRESS_LABEL = 406;

type DSMMetadata = {
  [ADDRESS_LABEL]: string | string[];
  [DATUM_LABEL]: string | string[];
};

const MINIMUM_PRICE = 50_000_000; // Minimum amount that a NFT can be sold for.
const MINTING_FEE = 5_000_000;

const DROPSPOT_ADDRESS =
  constants.NEXT_PUBLIC_DROPSPOT_ADDRESS || 'NOT_CONFIGURED';

const DROPSPOT_CUT = 400;
const MIN_LOVELACE = 2_000_000;

export class DropspotMarket {
  private loaded = false;
  private protocolParameters!: ProtocolParamsType;
  private contract: Contract;
  private CONTRACT!: PlutusScript;
  private dropspotAddress!: Address;
  private wallet?: Cardano;
  private mintUtxoLL = '1000000';
  private txConfig!: TransactionBuilderConfig;
  private era: 'Alonzo' | 'Vasil';

  constructor(contract: Contract, era: 'Alonzo' | 'Vasil', wallet?: Cardano) {
    this.initTx = this.initTx.bind(this);
    this.finalizeTx = this.finalizeTx.bind(this);
    this.load = this.load.bind(this);
    this.blockfrost = this.blockfrost.bind(this);
    this.wallet = wallet;
    this.contract = contract;
    this.era = era;
  }

  public setWallet(v: Cardano) {
    this.wallet = v;
  }

  /**
   * Pass in the Type <T> so that we get properly typed output... Seems okay!
   * @param path Path to the Blockfrost API that we want to call
   * @returns
   */
  private async blockfrost<T>(path: string, showErr = true): Promise<T> {
    const response = await fetch(getAbsoluteURL(`/api/blockfrost/${path}`), {
      headers: {
        accept: 'application/json',
      },
    });

    if (!response.ok) {
      showErr && console.error(await response.text());
      throw new Error('Unable to talk with blockfrost; check server logs!');
    }
    const json = await response.json();
    return json as unknown as T;
  }

  public async load() {
    if (this.loaded) {
      return;
    }

    await Loader.load();

    this.protocolParameters = await this.blockfrost<ProtocolParamsType>(
      'epochParams'
    );

    this.CONTRACT =
      this.contract.plutusVersion === 'PlutusV2'
        ? Loader.Cardano.PlutusScript.from_bytes_v2(fromHex(this.contract.cbor))
        : Loader.Cardano.PlutusScript.from_bytes(fromHex(this.contract.cbor));

    this.dropspotAddress = Loader.Cardano.Address.from_bech32(DROPSPOT_ADDRESS);

    const linearFee = Loader.Cardano.LinearFee.new(
      Loader.Cardano.BigNum.from_str(
        this.protocolParameters.min_fee_a.toString()
      ),
      Loader.Cardano.BigNum.from_str(
        this.protocolParameters.min_fee_b.toString()
      )
    );

    const exUnitPrices = Loader.Cardano.ExUnitPrices.new(
      Loader.Cardano.UnitInterval.new(
        Loader.Cardano.BigNum.from_str('577'),
        Loader.Cardano.BigNum.from_str('10000')
      ),
      Loader.Cardano.UnitInterval.new(
        Loader.Cardano.BigNum.from_str('721'),
        Loader.Cardano.BigNum.from_str('10000000')
      )
    );

    this.txConfig = Loader.Cardano.TransactionBuilderConfigBuilder.new()
      .fee_algo(linearFee)
      .pool_deposit(
        Loader.Cardano.BigNum.from_str(this.protocolParameters.pool_deposit)
      )
      .key_deposit(
        Loader.Cardano.BigNum.from_str(this.protocolParameters.key_deposit)
      )
      .coins_per_utxo_byte(
        Loader.Cardano.BigNum.from_str(
          this.protocolParameters.coins_per_utxo_size
        )
      )
      .max_value_size(Number.parseInt(this.protocolParameters.max_val_size, 10))
      .max_tx_size(this.protocolParameters.max_tx_size)
      .prefer_pure_change(true)
      .ex_unit_prices(exUnitPrices)
      .build();

    this.loaded = true;
  }

  private async initTx() {
    if (!this.protocolParameters) {
      throw new Error('DropspotMarket not initialized');
    }
    const txBuilder = Loader.Cardano.TransactionBuilder.new(this.txConfig);
    const datums = Loader.Cardano.PlutusList.new();
    const metadata: Partial<DSMMetadata> = {};
    const outputs = Loader.Cardano.TransactionOutputs.new();
    return { txBuilder, datums, metadata, outputs };
  }

  private async finalizeTx({
    txBuilder,
    changeAddress,
    utxos,
    outputs,
    datums,
    metadata,
    scriptUtxo,
    validityStartInterval,
    redeemers,
  }: {
    txBuilder: TransactionBuilder;
    changeAddress: BaseAddress;
    utxos: TransactionUnspentOutput[];
    outputs: TransactionOutputs;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    datums?: PlutusList;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata?: any;
    scriptUtxo?: TransactionUnspentOutput;
    validityStartInterval?: number;
    redeemers?: Redeemers;
  }) {
    if (!this.wallet)
      throw new DropspotMarketError({
        type: 'INTERNAL',
        code: InternalErrorCode.WALLET_NOT_SET,
        info: 'Wallet not set',
      });

    try {
      for (let i = 0; i < outputs.len(); i++) {
        txBuilder.add_output(outputs.get(i));
      }

      const txUnspendOutputs = Loader.Cardano.TransactionUnspentOutputs.new();

      utxos.forEach((u) => txUnspendOutputs.add(u));

      for (let x = 0; x < txUnspendOutputs.len(); x++) {
        const u = txUnspendOutputs.get(x);
        const amt = u.output().amount();
      }

      try {
        txBuilder.add_inputs_from(
          txUnspendOutputs,
          Loader.Cardano.CoinSelectionStrategyCIP2.LargestFirstMultiAsset
        );
      } catch (error) {
        console.log('Coin Selection Error');
        console.error(error);
        throw new DropspotMarketError({
          type: 'COIN_SELECTION',
          code: CoinSelectionErrorCode.INPUTS_EXHAUSTED,
          info: `You do not have enough funds in your wallet to process this transaction.`,
        });
      }

      if (validityStartInterval) {
        if (Date.now() < validityStartInterval) {
          throw new DropspotMarketError({
            type: 'INTERNAL',
            code: InternalErrorCode.VALIDITY_START_INTERVAL_NOT_MET,
            info: `Sale Start Date has not yet passed (Now: ${Date.now()}) (Start: ${validityStartInterval})`,
          });
        }
        try {
          const ttsResp = await this.blockfrost<TimeToSlotResponse>(
            `/timeToSlot/${Date.now() - 30}`
          );
          ttsResp.currentSlot &&
            txBuilder.set_validity_start_interval(ttsResp.currentSlot);
          ttsResp.currentSlot && txBuilder.set_ttl(ttsResp.currentSlot + 86400); // Transaction is valid for 24 hours
        } catch (err) {
          console.error(err);
        }
      }

      if (scriptUtxo) {
        if (!datums) {
          throw new DropspotMarketError({
            type: 'INTERNAL',
            code: InternalErrorCode.MissingDatum,
            info: 'Script Transactions should have Datums',
          });
        }
        if (!redeemers) {
          throw new DropspotMarketError({
            type: 'INTERNAL',
            code: InternalErrorCode.MissingDatum,
            info: 'Script Transactions should have redeemers',
          });
        }

        console.log('>>>>>>>>', 'set_script_data_hash');

        txBuilder.set_script_data_hash(
          Loader.Cardano.hash_script_data(
            redeemers,
            Loader.Cardano.TxBuilderConstants.plutus_vasil_cost_models(),
            datums
          )
        );

        try {
          // Stupid shit to handle Wallets not doing the right thing
          const getCollateral = this.wallet.getCollateral
            ? this.wallet.getCollateral
            : this.wallet.experimental?.getCollateral;

          if (!getCollateral) {
            throw new DropspotMarketError({
              code: InternalErrorCode.NO_COLLATERAL,
              info: 'NO_COLLATERAL',
              type: 'INTERNAL',
            });
          }

          const c = (await getCollateral()) || [];

          const collateral = c.map((utxo) =>
            Loader.Cardano.TransactionUnspentOutput.from_bytes(fromHex(utxo))
          );
          if (collateral.length <= 0) {
            console.error('No collateral found');
            throw new DropspotMarketError({
              code: InternalErrorCode.NO_COLLATERAL,
              info: 'NO_COLLATERAL',
              type: 'INTERNAL',
            });
          }

          const collateralTxBuilder = Loader.Cardano.TxInputsBuilder.new();

          collateral.forEach((utxo) => {
            collateralTxBuilder.add_input(
              utxo.output().address(),
              utxo.input(),
              utxo.output().amount()
            );
          });

          console.log('>>>>>>>>', 'set_collateral');
          txBuilder.set_collateral(collateralTxBuilder);
        } catch (err) {
          console.error('No collateral 2:', err);
          throw new DropspotMarketError({
            code: InternalErrorCode.NO_COLLATERAL,
            info: 'NO_COLLATERAL',
            type: 'INTERNAL',
          });
        }
      }

      let aux_data;

      if (metadata) {
        aux_data = Loader.Cardano.AuxiliaryData.new();
        const generalMetadata = Loader.Cardano.GeneralTransactionMetadata.new();
        Object.keys(metadata).forEach((label) => {
          Object.keys(metadata[label]).length > 0 &&
            generalMetadata.insert(
              Loader.Cardano.BigNum.from_str(label),
              Loader.Cardano.encode_json_str_to_metadatum(
                JSON.stringify(metadata[label]),
                1
              )
            );
        });
        aux_data.set_metadata(generalMetadata);
        txBuilder.set_auxiliary_data(aux_data);
      }

      txBuilder.add_change_if_needed(changeAddress.to_address());

      if (redeemers) {
        const language =
          this.contract.plutusVersion === 'PlutusV2'
            ? Loader.Cardano.Language.new_plutus_v2()
            : Loader.Cardano.Language.new_plutus_v1();

        const c =
          Loader.Cardano.TxBuilderConstants.plutus_vasil_cost_models().get(
            language
          );
        if (!c) {
          throw new Error('No Costmodel for Plutus Version of script');
        }
        const costMdl = Loader.Cardano.Costmdls.new();
        costMdl.insert(language, c);

        // txBuilder.calc_script_data_hash(
        //   Loader.Cardano.TxBuilderConstants.plutus_vasil_cost_models()
        // );

        const scriptDataHash = Loader.Cardano.ScriptDataHash.from_hex(
          'fafc2463bf897bbdbce2a49151146aaf6694677ad60d0da0876f8554bc0d16aa'
        );
        const hash1 = Loader.Cardano.hash_script_data(
          redeemers,
          Loader.Cardano.TxBuilderConstants.plutus_vasil_cost_models(),
          datums
        );
        const hash2 = Loader.Cardano.hash_script_data(
          redeemers,
          costMdl,
          datums
        );

        console.log('Hash Comp', hash1.to_hex(), hash2.to_hex());

        txBuilder.set_script_data_hash(scriptDataHash);
      }

      // let tx: Transaction;

      // When we can update the Redeemers and recalculate the fee then
      // Lets reenable the below code.
      // if (redeemers) {
      //   tx = txBuilder.build_tx();
      //   try {
      //     const exUnitsRedeemers = await evaluateTxn(tx.to_hex());

      //     const updRedeemers = Loader.Cardano.Redeemers.new();

      //     for (let i = 0; i < redeemers.len(); i++) {
      //       const r = redeemers.get(i);

      //       const index = r.index().checked_add(Loader.Cardano.BigNum.one());

      //       const key = `${redeemerTagKindToText(
      //         r.tag().kind()
      //       )}:${index.to_str()}`;

      //       const exUnits = exUnitsRedeemers.result.EvaluationResult[key];
      //       if (!exUnits) throw new Error('Unable to locate Redeemer');

      //       const redeemer = Loader.Cardano.Redeemer.new(
      //         r.tag(),
      //         r.index(),
      //         r.data(),
      //         Loader.Cardano.ExUnits.new(
      //           Loader.Cardano.BigNum.from_str(exUnits.memory.toString()),
      //           Loader.Cardano.BigNum.from_str(exUnits.steps.toString())
      //         )
      //       );

      //       updRedeemers.add(redeemer);
      //     }

      //     txBuilder.set_script_data_hash(
      //       Loader.Cardano.hash_script_data(
      //         updRedeemers,
      //         Loader.Cardano.TxBuilderConstants.plutus_vasil_cost_models(),
      //         datums
      //       )
      //     );

      //     txBuilder.set_fee(txBuilder.min_fee()); //Update Fee
      //     console.log('Redeemer Update TX', tx.to_hex());
      //   } catch (err) {
      //     console.error('ExUnit Evaluate', err);
      //   }
      // }

      let tx: Transaction;
      try {
        tx = txBuilder.build_tx();
      } catch (err) {
        console.log('error: ', err);
        throw new DropspotMarketError({
          code: 100,
          info: (err as Error).message,
          type: 'BUILD',
        });
      }

      const transactionWitnessSet =
        Loader.Cardano.TransactionWitnessSet.from_bytes(
          tx.witness_set().to_bytes()
        );
      // if (this.contract.plutusVersion === 'PlutusV2') {
      //   // Build up the Transaction Witness Set by hand
      //   transactionWitnessSet = Loader.Cardano.TransactionWitnessSet.new();
      //   const currentWitnessSet = tx.witness_set();
      //   const pd = currentWitnessSet.plutus_data();
      //   if (pd) transactionWitnessSet.set_plutus_data(pd);

      //   const r = currentWitnessSet.redeemers();
      //   if (r) transactionWitnessSet.set_redeemers(r);
      // } else {
      //   transactionWitnessSet = Loader.Cardano.TransactionWitnessSet.from_bytes(
      //     tx.witness_set().to_bytes()
      //   );
      // }

      let txVkeyWitnessesHex;
      try {
        txVkeyWitnessesHex = await this.wallet.signTx(
          toHex(tx.to_bytes()),
          true // Partial Signing allowed
        );
      } catch (err) {
        console.error('Signing Error', err);
        throw new DropspotMarketError({
          code: (err as APIError).code,
          info: (err as APIError).info,
          type: 'SIGN',
        });
      }

      // console.log('Sign TX - Signed');
      const txVkeyWitnesses = Loader.Cardano.TransactionWitnessSet.from_bytes(
        fromHex(txVkeyWitnessesHex)
      );

      const vkeys = txVkeyWitnesses.vkeys();
      if (vkeys) {
        transactionWitnessSet.set_vkeys(vkeys);
      }

      const signedTx = Loader.Cardano.Transaction.new(
        tx.body(),
        transactionWitnessSet,
        tx.auxiliary_data()
      );

      // console.log('Full Tx Size', signedTx.to_bytes().length);

      console.log(signedTx.to_hex());

      try {
        const response = await fetch(
          getAbsoluteURL(`/api/blockfrost/plutus/utils/txs/submit`),
          {
            method: 'POST',
            body: Buffer.from(signedTx.to_bytes()).toString('hex'),
            headers: {
              'Content-Type': 'application/cbor',
            },
          }
        );

        if (!response.ok) {
          console.error(await response.json());
          throw new ApiError(999, 'Submit error');
        }

        return (await response.json()) as string;

        // const txHash = await this.wallet.submitTx(toHex(signedTx.to_bytes()));
        // return txHash;
      } catch (err) {
        console.error('Submit error');
        console.error(err);
        throw new DropspotMarketError({
          code: 2,
          info: (err as APIError).info,
          type: 'SEND',
        });
      }
    } catch (err) {
      console.error('Finalize Txn Error', err);
      throw err;
    }
  }

  private printIO(inputs: TransactionInputs, outputs: TransactionOutputs) {
    console.log('In printIO');
    const record: {
      inputs: { txHash: string; txId: number }[];
      outputs: {
        address: string;
        amount: { [key: string]: string };
        dataHash: string;
      }[];
    } = {
      inputs: [],
      outputs: [],
    };
    for (let i = 0; i < outputs.len(); i++) {
      const output = outputs.get(i);

      const amount: Record<string, string> = {
        lovelace: output.amount().coin().to_str(),
      };

      const ma = output.amount().multiasset();
      if (ma) {
        const keys = ma.keys();

        for (let j = 0; j < keys.len(); j++) {
          const key = keys.get(j);

          const assets = ma.get(key);

          if (assets) {
            for (let x = 0; x < assets.len(); x++) {
              const policy = toHex(assets.to_bytes());
              const assetNames = assets.keys();

              for (let y = 0; y < assetNames.len(); y++) {
                const assetName = assetNames.get(y);
                const hexName = Buffer.from(assetName.name()).toString('utf-8');
                amount[`${policy}.${hexName}`] =
                  assets.get(assetName)?.to_str() || '<Not Set>';
              }
            }
          }
        }
      }

      const hash = output.data_hash();

      record.outputs.push({
        address: output.address().to_bech32(),
        dataHash: !!hash ? toHex(hash.to_bytes()) : '<NOT SET>',
        amount,
      });
    }

    console.debug(JSON.stringify(record, null, 2));
  }

  private serializeTxn(txn: Transaction) {
    const record: {
      inputs: { txHash: string; txId: number }[];
      outputs: {
        address: string;
        amount: { [key: string]: string };
        dataHash: string;
      }[];
    } = {
      inputs: [],
      outputs: [],
    };
    const inputs = txn.body().inputs();
    const outputs = txn.body().outputs();

    for (let i = 0; i < inputs.len(); i++) {
      const input = inputs.get(i);

      record.inputs.push({
        txHash: toHex(input.transaction_id().to_bytes()),
        txId: input.index(),
      });
    }

    for (let i = 0; i < outputs.len(); i++) {
      const output = outputs.get(i);

      const amount: Record<string, string> = {
        lovelace: output.amount().coin().to_str(),
      };

      const ma = output.amount().multiasset();
      if (ma) {
        const keys = ma.keys();

        for (let j = 0; j < keys.len(); j++) {
          const key = keys.get(j);

          const assets = ma.get(key);

          if (assets) {
            for (let x = 0; x < assets.len(); x++) {
              const policy = toHex(assets.to_bytes());
              const assetNames = assets.keys();

              for (let y = 0; y < assetNames.len(); y++) {
                const assetName = assetNames.get(y);
                const hexName = Buffer.from(assetName.name()).toString('utf-8');
                amount[`${policy}.${hexName}`] =
                  assets.get(assetName)?.to_str() || '<Not Set>';
              }
            }
          }
        }
      }

      const hash = output.data_hash();

      record.outputs.push({
        address: output.address().to_bech32(),
        dataHash: !!hash ? toHex(hash.to_bytes()) : '<NOT SET>',
        amount,
      });
    }

    return record;
  }

  private async createOutput(
    address: Address,
    value: Value,
    {
      datum,
      index,
      tradeOwnerAddress,
      metadata,
      datumJson,
    }: {
      datum?: PlutusData;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      datumJson?: any;
      index: number;
      tradeOwnerAddress?: BaseAddress;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      metadata?: any;
    } = { index: 0 }
  ) {
    console.log('createOutput', 'IN');
    const v = value;
    const output = Loader.Cardano.TransactionOutput.new(address, v);

    if (datum) {
      console.log('createOutput', 'datum', toHex(datum.to_bytes()));

      const hash = Loader.Cardano.hash_plutus_data(datum);
      output.set_data_hash(hash);

      // Save the Metadata Datum under the Hash so that we can retrieve later on
      try {
        await saveOfferMetadata(toHex(hash.to_bytes()), {
          [index]: toHex(datum.to_bytes()),
          _metadata: datumJson,
        });
      } catch (e) {
        console.error('ERRORERROR', e);
      }
    }

    if (tradeOwnerAddress) {
      metadata[ADDRESS_LABEL].address =
        '0x' + toHex(tradeOwnerAddress.to_address().to_bytes());
    }

    const minAda = Loader.Cardano.min_ada_for_output(
      output,
      Loader.Cardano.DataCost.new_coins_per_byte(
        Loader.Cardano.BigNum.from_str(
          this.protocolParameters.coins_per_utxo_size
        )
      )
    );

    console.log('Output for MIN ADA Calc', output.to_json(), minAda.to_str());

    if (output.amount().coin().less_than(minAda)) {
      console.debug(
        '>>>>>',
        'Output less than MinAda',
        output.amount().coin().to_str(),
        minAda.to_str()
      );
      const value = output.amount();
      value.set_coin(minAda);

      const output2 = Loader.Cardano.TransactionOutput.new(address, value);
      if (output.has_data_hash()) {
        const hash = output.data_hash();
        if (hash) output2.set_data_hash(hash);
      }

      return output2;
    }

    console.log('Out: createOutput', toHex(output.to_bytes()));
    return output;
  }

  private isRoyaltyMetadata(m: unknown): m is RoyaltyMetadata {
    return (m as RoyaltyMetadata).addr !== undefined;
  }

  public async createOffer(offer: OfferOptions) {
    console.log('In createOffer', offer.assetName);
    //todo this needs to be fixed.
    if (!this.wallet)
      throw new Error('Cardano Wallet not installed (finalizeTx)');

    if (offer.askingPrice < MINIMUM_PRICE) {
      //like this:
      throw new DropspotMarketError({
        type: 'LISTING',
        code: ListingErrorCode.BelowMinPrice,
        info: 'MIN_PRICE_CUSTOM',
      });
    }

    const { txBuilder, datums, metadata, outputs } = await this.initTx();
    const walletAddr = Loader.Cardano.Address.from_bytes(
      fromHex((await this.wallet.getUsedAddresses())[0])
    );
    const walletBaseAddress =
      Loader.Cardano.BaseAddress.from_address(walletAddr);

    if (!walletBaseAddress) {
      throw new Error('NO_WALLET_ADDESS');
    }

    metadata[ADDRESS_LABEL] = splitStr(toHex(walletAddr.to_bytes()));

    const utxos = ((await this.wallet.getUtxos()) || []).map((utxo) =>
      Loader.Cardano.TransactionUnspentOutput.from_bytes(fromHex(utxo))
    );

    const walletKeyHash = walletBaseAddress.payment_cred().to_keyhash();

    if (!walletKeyHash) {
      throw new Error('NO_WALLET_ADDESS_KEY_HASH');
    }

    const royalties = await this.getRoyalty(offer.policy);

    const offerMetadata: OfferDataType = {
      ownerAddress: toHex(walletKeyHash.to_bytes()),
      price: offer.askingPrice,
      policy: offer.policy,
      assetName: fromHex(offer.assetName).toString('utf8'),
      startDatePOSIX: `${new Date().getTime()}`,
      royalties: royalties.map((r) => ({
        address: r.addr.to_bytes(),
        percent: Number.parseFloat(r.rate),
      })),
      disbursements: [],
    };

    const offerDatum = OFFER(offerMetadata);

    outputs.add(
      await this.createOutput(
        Loader.Cardano.Address.from_bech32(this.contract.address),
        assetsToValue([
          {
            unit: `${offer.policy}${offer.assetName}`,
            quantity: '1',
          },
        ]),
        {
          datum: offerDatum,
          datumJson: {
            ...offerMetadata,
            royalties: offerMetadata.royalties.map((r) => ({
              percent: r.percent,
              address:
                r.address &&
                Loader.Cardano.Address.from_bytes(r.address).to_bech32(),
            })),
          },
          index: 0,
          tradeOwnerAddress: walletBaseAddress,
          metadata,
        }
      )
    );
    datums.add(offerDatum);

    const requiredSigners = Loader.Cardano.Ed25519KeyHashes.new();

    requiredSigners.add(walletKeyHash);
    console.log('offerDatum', toHex(offerDatum.to_bytes()));

    metadata[DATUM_LABEL] = splitStr(toHex(datums.to_bytes()));

    const txHash = await this.finalizeTx({
      txBuilder,
      changeAddress: walletBaseAddress,
      utxos,
      outputs,
      datums,
      metadata,
    });

    await updateAssetListing(
      offer.assetId,
      offer.askingPrice,
      txHash,
      'LISTED'
    );

    return txHash;
  }

  private async getRoyalty(policy: string) {
    try {
      const metadata = await this.blockfrost<
        (
          | {
              label: string;
              json_metadata: unknown;
            }
          | {
              label: '777';
              json_metadata: Royalty777 | Extended777;
            }
        )[]
      >(`/plutus/policy/${policy}/metadata`);

      const m = metadata.find((m) => m.label === '777');

      if (!m) return [];

      const royalties: Royalty777[] = [];

      if (Array.isArray(m.json_metadata)) {
        // Extended 777 token
        (m.json_metadata as Extended777).map((a) => {
          const rate = a.rate || a.pct;
          if (!rate) throw new Error('NO_RATE');

          royalties.push({
            addr: Array.isArray(a.addr) ? a.addr.join('') : a.addr,
            rate,
          });
        });
      } else if (this.isRoyaltyMetadata(m.json_metadata)) {
        const addr = Array.isArray(m.json_metadata.addr)
          ? m.json_metadata.addr.join('')
          : m.json_metadata.addr;

        const rate = m.json_metadata.rate || m.json_metadata.pct;
        if (!rate) throw new Error('NO_RATE');
        royalties.push({
          addr,
          rate,
        });
      }

      return royalties
        .map((r) => {
          const addr = Loader.Cardano.Address.from_bech32(r.addr);
          const addressHash = Loader.Cardano.BaseAddress.from_address(addr)
            ?.payment_cred()
            .to_keyhash();

          if (!addressHash) return;

          return {
            addr,
            hash: addressHash.to_bytes(),
            rate: r.rate,
          };
        })
        .filter(
          (r): r is { addr: Address; hash: Uint8Array; rate: string } => !!r
        );
    } catch (err) {
      console.error(err);
    }

    return [];
  }

  public async getOffer(
    policy: string,
    tokenName: string
  ): Promise<TradeUtxo | null> {
    return _getOffer(this.contract.address, policy, tokenName);
  }

  // public async relist({
  //   askingPrice,
  //   utxo: offerUtxo,
  //   disbursements,
  //   startDate,
  //   assetId,
  // }: RelistOptions) {
  //   if (!this.wallet) throw new Error('Wallet not installed (cancel)');

  //   if (!offerUtxo.datum) {
  //     throw new Error('Cannot work without Datum');
  //   }

  //   const { txBuilder, datums, outputs, metadata } = await this.initTx();
  //   const walletAddr = Loader.Cardano.Address.from_bytes(
  //     fromHex((await this.wallet.getUsedAddresses())[0])
  //   );
  //   const walletAddress = Loader.Cardano.BaseAddress.from_address(walletAddr);
  //   if (!walletAddress) {
  //     throw new Error('Cannot parse Wallet Address');
  //   }
  //   const walletKeyHash = walletAddress.payment_cred()?.to_keyhash();

  //   if (!walletKeyHash) {
  //     throw new Error('Cannot get the Wallet Key Hash');
  //   }

  //   metadata[ADDRESS_LABEL] = splitStr(toHex(walletAddr.to_bytes()));

  //   const utxos = ((await this.wallet.getUtxos()) || []).map((utxo) =>
  //     Loader.Cardano.TransactionUnspentOutput.from_bytes(fromHex(utxo))
  //   );
  //   datums.add(offerUtxo.datum);

  //   const datum = offerUtxo.datum.as_constr_plutus_data()?.data();

  //   if (!datum) {
  //     throw new Error('Datum in not valid for Relisting');
  //   }

  //   const royaltiesDatum = datum
  //     .get(0)
  //     .as_constr_plutus_data()
  //     ?.data()
  //     .get(0)
  //     .as_list();

  //   const disDatum = datum
  //     .get(0)
  //     .as_constr_plutus_data()
  //     ?.data()
  //     .get(0)
  //     .as_list();

  //   // We do not care at all about the 'amount' and so passing 999 is so we can reuse code.
  //   const royalties: DisAmount[] = disDatumToArray(999, royaltiesDatum);

  //   let updDisbursements: DisbType[] = disDatumToArray(999, disDatum);
  //   if (disbursements) {
  //     updDisbursements = disbursements.map((d) => {
  //       return {
  //         address: Loader.Cardano.Address.from_bech32(
  //           Array.isArray(d.addr) ? d.addr.join('') : d.addr
  //         ).to_bytes(),
  //         percent: Number.parseFloat(d.rate),
  //       };
  //     });
  //   }

  //   const startDatePOSIX = datum.get(6).as_integer()?.to_str();

  //   const tokenDatum = datum.get(5).as_bytes();
  //   const policyDatum = datum.get(4).as_bytes();

  //   if (!tokenDatum) throw new Error('1001. Cannot get Token Datum');
  //   if (!policyDatum) throw new Error('1002. Cannot get Policy Datum');

  //   const policy = toHex(policyDatum);
  //   const token = toHex(tokenDatum);

  //   const offerMetadata: OfferDataType = {
  //     ownerAddress: toHex(walletKeyHash.to_bytes()),
  //     price: askingPrice,
  //     policy: policy,
  //     assetName: token,
  //     startDatePOSIX: `${startDate || startDatePOSIX || Date.now()}`,
  //     royalties,
  //     disbursements: updDisbursements,
  //   };

  //   const offerDatum = OFFER(offerMetadata);

  //   metadata[DATUM_LABEL] = splitStr(toHex(datums.to_bytes()));

  //   outputs.add(
  //     await this.createOutput(
  //       Loader.Cardano.Address.from_bech32(this.contract.address),
  //       assetsToValue([
  //         {
  //           unit: `${policy}${token}`,
  //           quantity: '1',
  //         },
  //       ]),
  //       {
  //         datum: offerDatum,
  //         datumJson: offerMetadata,
  //         index: 0,
  //         tradeOwnerAddress: walletAddress,
  //         metadata,
  //       }
  //     )
  //   );
  //   const txHash = await this.finalizeTx({
  //     txBuilder,
  //     changeAddress: walletAddress,
  //     utxos,
  //     outputs,
  //     datums,
  //     metadata,
  //     scriptUtxo: offerUtxo.utxo,
  //     action: RELIST,
  //   });

  //   await updateAssetListing(assetId, askingPrice, txHash, 'LISTED');

  //   return txHash;
  // }

  public async cancel(offerUtxo: TradeUtxo, assetId: string) {
    if (!this.wallet) throw new Error('Wallet not installed (cancel)');

    if (!offerUtxo.datum)
      throw new DropspotMarketError({
        type: 'INTERNAL',
        code: InternalErrorCode.MissingDatum,
        info: 'Datum not attached to the offerUtxo',
      });

    const { txBuilder, datums, outputs } = await this.initTx();

    const walletAddress = Loader.Cardano.BaseAddress.from_address(
      Loader.Cardano.Address.from_bytes(
        fromHex((await this.wallet.getUsedAddresses())[0])
      )
    );
    if (!walletAddress) {
      throw new Error('Cannot parse Wallet Address');
    }
    const walletKeyHash = walletAddress.payment_cred()?.to_keyhash();

    if (!walletKeyHash) {
      throw new Error('Cannot get the Wallet Key Hash');
    }
    txBuilder.add_required_signer(walletKeyHash);

    const utxos = ((await this.wallet.getUtxos()) || []).map((utxo) =>
      Loader.Cardano.TransactionUnspentOutput.from_bytes(fromHex(utxo))
    );
    datums.add(offerUtxo.datum);

    // Dropspot should get their Minting Fee
    outputs.add(
      await this.createOutput(
        this.dropspotAddress,
        Loader.Cardano.Value.new(
          Loader.Cardano.BigNum.from_str(`${MINTING_FEE}`)
        )
      )
    );

    // User should get their Token back

    outputs.add(
      await this.createOutput(
        walletAddress.to_address(),
        offerUtxo.utxo.output().amount()
      )
    );

    const redeemerData = Loader.Cardano.PlutusData.new_constr_plutus_data(
      Loader.Cardano.ConstrPlutusData.new(
        Loader.Cardano.BigNum.from_str('2'),
        Loader.Cardano.PlutusList.new()
      )
    );

    const redeemer = Loader.Cardano.Redeemer.new(
      Loader.Cardano.RedeemerTag.new_spend(),
      Loader.Cardano.BigNum.from_str('0'),
      redeemerData,
      Loader.Cardano.ExUnits.new(
        Loader.Cardano.BigNum.from_str('7000000'),
        Loader.Cardano.BigNum.from_str('3000000000')
      )
    );
    const redeemers = Loader.Cardano.Redeemers.new();
    redeemers.add(redeemer);

    let plutusWitness: PlutusWitness;

    if (this.contract.plutusVersion === 'PlutusV2') {
      const txRefInput = Loader.Cardano.TransactionInput.new(
        Loader.Cardano.TransactionHash.from_hex(this.contract.refInputHash),
        this.contract.refInputIndex
      );

      console.log(
        'Script Hash',
        this.CONTRACT.hash().to_hex(),
        this.contract.address
      );

      const plutusScriptSource =
        Loader.Cardano.PlutusScriptSource.new_ref_input(
          this.CONTRACT.hash(),
          txRefInput
        );

      // const plutusScriptSource = Loader.Cardano.PlutusScriptSource.new(
      //   this.CONTRACT
      // );
      const datumSource = Loader.Cardano.DatumSource.new(
        Loader.Cardano.PlutusData.from_bytes(offerUtxo.datum.to_bytes())
      );

      plutusWitness = Loader.Cardano.PlutusWitness.new_with_ref(
        plutusScriptSource,
        datumSource,
        redeemer
      );
    } else {
      plutusWitness = Loader.Cardano.PlutusWitness.new(
        this.CONTRACT,
        Loader.Cardano.PlutusData.from_bytes(offerUtxo.datum.to_bytes()),
        Loader.Cardano.Redeemer.from_bytes(redeemer.to_bytes())
      );
    }

    txBuilder.add_plutus_script_input(
      plutusWitness,
      offerUtxo.utxo.input(),
      offerUtxo.utxo.output().amount()
    );

    console.log(
      'Missing Input Scripts',
      txBuilder.count_missing_input_scripts()
    );

    const txHash = await this.finalizeTx({
      txBuilder,
      changeAddress: walletAddress,
      utxos,
      outputs,
      datums,
      scriptUtxo: offerUtxo.utxo,
      redeemers,
    });

    try {
      console.log('txHash - before updateAssetListing', txHash);
      await updateAssetListing(assetId, null, txHash, 'UNLISTED');
      console.log('txHash - after updateAssetListing', txHash);
    } catch (e) {
      console.error('Error updating Asset Listing', e);
    }

    return txHash;
  }

  public async buy(offerUtxo: TradeUtxo, amount: number, assetId: string) {
    if (!this.wallet) throw new Error('Cardano Wallet not installed (buy)');
    if (!offerUtxo.datum)
      throw new DropspotMarketError({
        code: 989,
        info: 'No Datum',
        type: 'INTERNAL',
      });
    const { txBuilder, datums, outputs } = await this.initTx();
    const walletAddress = Loader.Cardano.BaseAddress.from_address(
      Loader.Cardano.Address.from_bytes(
        fromHex((await this.wallet.getUsedAddresses())[0])
      )
    );

    if (!walletAddress) {
      throw new Error('Cannot parse Wallet Address');
    }
    const walletKeyHash = walletAddress.payment_cred()?.to_keyhash();

    if (!walletKeyHash) {
      throw new Error('Cannot get the Wallet Key Hash');
    }

    txBuilder.add_required_signer(walletKeyHash);

    const utxos = ((await this.wallet.getUtxos()) || []).map((utxo) =>
      Loader.Cardano.TransactionUnspentOutput.from_bytes(fromHex(utxo))
    );

    datums.add(offerUtxo.datum);

    const value = offerUtxo.utxo.output().amount();
    const lovelaceAmount = amount;

    // const royalties = await this.getRoyalty(offerUtxo.policy);
    const split = this.calculateSplit(lovelaceAmount, offerUtxo);

    console.debug('split', JSON.stringify(split));
    if (!split) {
      throw new Error('Unable to calculate Split');
    }

    for (const s of split.royalties) {
      if (!s.address) continue;
      outputs.add(
        await this.createOutput(
          Loader.Cardano.Address.from_bytes(s.address),
          Loader.Cardano.Value.new(
            Loader.Cardano.BigNum.from_str(`${s.amount}`)
          )
        )
      );
    }

    for (const s of split.disbursements) {
      if (!s.address) continue;
      outputs.add(
        await this.createOutput(
          Loader.Cardano.Address.from_bytes(s.address),
          Loader.Cardano.Value.new(
            Loader.Cardano.BigNum.from_str(`${s.amount}`)
          )
        )
      );
    }

    outputs.add(
      await this.createOutput(
        this.dropspotAddress,
        Loader.Cardano.Value.new(
          Loader.Cardano.BigNum.from_str(split.marketPLaceAmount.toString())
        )
      )
    );

    if (!offerUtxo.metadata?._uid) {
      throw new Error('Unable to get Trade Owner Address');
    }
    // Trade Owner - Gets the remaining Amount

    let tradeOwnerAddress;
    if (offerUtxo.metadata?._uid.startsWith('addr')) {
      tradeOwnerAddress = Loader.Cardano.Address.from_bech32(
        offerUtxo.metadata._uid
      );
    } else {
      tradeOwnerAddress = Loader.Cardano.Address.from_bytes(
        fromHex(offerUtxo.metadata._uid)
      );
    }

    const remaining = amount - split.marketPLaceAmount; // - split.royaltyAmount;
    outputs.add(
      await this.createOutput(
        tradeOwnerAddress,
        Loader.Cardano.Value.new(
          Loader.Cardano.BigNum.from_str(remaining.toString())
        )
      )
    );

    // NFT and Change
    outputs.add(await this.createOutput(walletAddress.to_address(), value));

    const startDate = offerUtxo.metadata?._metadata.startDatePOSIX;

    let startDatePosix: number | undefined = undefined;
    if (startDate) startDatePosix = Number.parseInt(startDate);

    const redeemerData = Loader.Cardano.PlutusData.new_constr_plutus_data(
      Loader.Cardano.ConstrPlutusData.new(
        Loader.Cardano.BigNum.from_str('0'),
        Loader.Cardano.PlutusList.new()
      )
    );

    const redeemer = Loader.Cardano.Redeemer.new(
      Loader.Cardano.RedeemerTag.new_spend(),
      Loader.Cardano.BigNum.from_str('0'),
      redeemerData,
      Loader.Cardano.ExUnits.new(
        Loader.Cardano.BigNum.from_str('7000000'),
        Loader.Cardano.BigNum.from_str('3000000000')
      )
    );

    let plutusWitness: PlutusWitness;

    if (this.contract.plutusVersion === 'PlutusV2') {
      const txRefInput = Loader.Cardano.TransactionInput.new(
        Loader.Cardano.TransactionHash.from_hex(this.contract.refInputHash),
        this.contract.refInputIndex
      );

      const plutusScriptSource =
        Loader.Cardano.PlutusScriptSource.new_ref_input(
          this.CONTRACT.hash(),
          txRefInput
        );

      const datumSource = Loader.Cardano.DatumSource.new(
        Loader.Cardano.PlutusData.from_bytes(offerUtxo.datum.to_bytes())
      );

      plutusWitness = Loader.Cardano.PlutusWitness.new_with_ref(
        plutusScriptSource,
        datumSource,
        redeemer
      );
    } else {
      plutusWitness = Loader.Cardano.PlutusWitness.new(
        this.CONTRACT,
        Loader.Cardano.PlutusData.from_bytes(offerUtxo.datum.to_bytes()),
        Loader.Cardano.Redeemer.from_bytes(redeemer.to_bytes())
      );
    }

    txBuilder.add_plutus_script_input(
      plutusWitness,
      offerUtxo.utxo.input(),
      offerUtxo.utxo.output().amount()
    );

    const redeemers = Loader.Cardano.Redeemers.new();
    redeemers.add(redeemer);

    const txHash = await this.finalizeTx({
      txBuilder,
      changeAddress: walletAddress,
      utxos,
      outputs,
      datums,
      validityStartInterval: startDatePosix,
      scriptUtxo: offerUtxo.utxo,
      redeemers,
    });

    try {
      console.log('txHash - before updateAssetListing', txHash);
      await updateAssetListing(assetId, null, txHash, 'UNLISTED');
      console.log('txHash - after updateAssetListing', txHash);
    } catch (e) {
      console.error('Error updating Asset Listing', e);
    }
    return txHash;
  }

  /**
   *   
  { royaltyOwner :: !PaymentPubKeyHash, -- Wallet address for Dropspot
    royaltyPCT :: !Integer, -- Percentage to be paid to the market place owner
    tradeOwner :: !PaymentPubKeyHash, -- Owners Wallet Public Key hash
    amount :: !Integer, -- Minimum Trade Amount for Token
    policy :: !CurrencySymbol, -- Policy of NFT that we are selling
    token :: !TokenName, -- NFT that we are selling
    startDate :: !POSIXTime -- The NFT cannot be purchased before this Date
  }
   * @param total 
   * @param utxo 
   * @returns 
   */
  calculateSplit(total: number, utxo: TradeUtxo) {
    if (!utxo.datum) {
      return null;
    }

    const datum = utxo.datum.as_constr_plutus_data()?.data();

    if (!datum) {
      return null;
    }

    const royaltiesDatum = datum
      .get(0)
      .as_constr_plutus_data()
      ?.data()
      .get(0)
      .as_list();
    const royalties: DisAmount[] = disDatumToArray(total, royaltiesDatum);

    const disDatum = datum
      .get(0)
      .as_constr_plutus_data()
      ?.data()
      .get(0)
      .as_list();

    const marketPLaceAmount = llPct(total, DROPSPOT_CUT);

    const totalRoyalties = royalties.reduce((pv, cur) => pv + cur.amount, 0);

    const remaining = total - totalRoyalties - marketPLaceAmount;

    const disbursements: DisAmount[] = disDatumToArray(remaining, disDatum);

    const tradeOwner = datum.get(2).as_bytes();

    if (!tradeOwner) {
      throw new Error('No tradeOwner address in Datum');
    }

    return {
      royalties,
      disbursements,
      tradeOwner,
      marketPLaceAmount,
      totalRoyalties,
    };
  }
}

/**
 *
 * @param {string} txHash Transaction Id
 * @returns
 */
export const awaitConfirmation = (txHash?: string): Promise<boolean> => {
  if (!txHash) return Promise.resolve(false);

  let attempts = 0;
  //
  const isConfirmed = async () => {
    try {
      const txn = await blockfrost<components['schemas']['tx_content']>(
        `/plutus/txns/${txHash}`,
        false
      );

      if (txn) {
        return true;
      }
    } catch (_) {
      console.log('Error getting txn', _);
    }

    return false;
  };

  const p = new Promise<boolean>((resolve) => {
    const x = setInterval(() => {
      attempts += 1;
      if (attempts > MAX_ATTEMPTS) {
        clearInterval(x);
        resolve(false);
      }

      isConfirmed().then((confirmed) => {
        if (confirmed) {
          clearInterval(x);
          resolve(true);
        }
      });
    }, 5000);
  });

  return p;
};

/** DATUM Generation */

// const ASSET = (assetName: string, policy: string) => {
//   const fieldsInner = Loader.Cardano.PlutusList.new();
//   fieldsInner.add(Loader.Cardano.PlutusData.new_bytes(fromHex(policy)));
//   fieldsInner.add(
//     Loader.Cardano.PlutusData.new_bytes(Buffer.from(assetName, 'utf-8'))
//   );

//   return Loader.Cardano.PlutusData.new_constr_plutus_data(
//     Loader.Cardano.ConstrPlutusData.new(
//       Loader.Cardano.Int.new_i32(DATUM_TYPE.Asset),
//       fieldsInner
//     )
//   );
// };

// const RELIST = (index: string) => {
//   const redeemerData = Loader.Cardano.PlutusData.new_constr_plutus_data(
//     Loader.Cardano.ConstrPlutusData.new(
//       Loader.Cardano.BigNum.from_str('1'),
//       Loader.Cardano.PlutusList.new()
//     )
//   );

//   const redeemer = Loader.Cardano.Redeemer.new(
//     Loader.Cardano.RedeemerTag.new_spend(),
//     Loader.Cardano.BigNum.from_str(index),
//     redeemerData,
//     Loader.Cardano.ExUnits.new(
//       Loader.Cardano.BigNum.from_str('7000000'),
//       Loader.Cardano.BigNum.from_str('3000000000')
//     )
//   );
//   return redeemer;
// };

type DisbType = {
  percent: number;
  address: Uint8Array | undefined;
};

type OfferDataType = {
  royalties: DisbType[];
  disbursements: DisbType[];
  ownerAddress: string;
  price: number;
  policy: string;
  assetName: string;
  startDatePOSIX: string;
};

export const OFFER = ({
  royalties,
  disbursements,
  assetName,
  ownerAddress,
  policy,
  price,
  startDatePOSIX,
}: OfferDataType) => {
  console.log('===============IN OFFER==================');
  console.log(
    JSON.stringify({
      royalties,
      disbursements,
      assetName,
      ownerAddress,
      policy,
      price,
      startDatePOSIX,
    })
  );
  const royaltiesList = Loader.Cardano.PlutusList.new();
  const disList = Loader.Cardano.PlutusList.new();

  royalties.map((royalty) => royaltiesList.add(disbursementToPlutus(royalty)));
  disbursements.map((dis) => disList.add(disbursementToPlutus(dis)));

  const fieldsInner = Loader.Cardano.PlutusList.new();

  // Royalties
  fieldsInner.add(Loader.Cardano.PlutusData.new_list(royaltiesList));

  // Disbursements
  fieldsInner.add(Loader.Cardano.PlutusData.new_list(disList));

  // tradeOwner
  fieldsInner.add(Loader.Cardano.PlutusData.new_bytes(fromHex(ownerAddress)));

  // amount
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_integer(
      Loader.Cardano.BigInt.from_str(`${price}`)
    )
  );

  // policy
  fieldsInner.add(Loader.Cardano.PlutusData.new_bytes(fromHex(policy)));

  // token
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_bytes(Buffer.from(assetName, 'utf-8'))
  );

  // Start Date
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_integer(
      Loader.Cardano.BigInt.from_str(startDatePOSIX)
    )
  );

  const datum = Loader.Cardano.PlutusData.new_constr_plutus_data(
    Loader.Cardano.ConstrPlutusData.new(
      Loader.Cardano.BigNum.from_str(DATUM_TYPE.Offer.toString()),
      fieldsInner
    )
  );
  console.log('=============OUT OFFER===================');
  console.log(toHex(datum.to_bytes()));
  return datum;
};

function disbursementToPlutus(dis: DisbType) {
  console.log('====================================');
  console.log(JSON.stringify(dis));
  console.log('PCT: ', `${percentageToContractPCT(dis.percent)}`);
  console.log('====================================');
  if (!dis.address) throw new Error('Address is undefined'); // Throw?

  const fields = Loader.Cardano.PlutusList.new();

  console.log('To Address: ', toHex(dis.address));
  const address = Loader.Cardano.Address.from_bytes(dis.address);
  console.log('Got Address: ', toHex(address.to_bytes()));

  const baseAddress = Loader.Cardano.BaseAddress.from_address(address);
  if (!baseAddress) throw new Error('Unable to get Base Address');

  const keyHash = baseAddress.payment_cred().to_keyhash();

  if (!keyHash) throw new Error('Keyhash is undefined. Bad Address?');

  fields.add(Loader.Cardano.PlutusData.new_bytes(keyHash.to_bytes()));

  fields.add(
    Loader.Cardano.PlutusData.new_integer(
      Loader.Cardano.BigInt.from_str(`${percentageToContractPCT(dis.percent)}`)
    )
  );

  return Loader.Cardano.PlutusData.new_constr_plutus_data(
    Loader.Cardano.ConstrPlutusData.new(
      Loader.Cardano.BigNum.from_str('0'),
      fields
    )
  );
}

export const OFFER2 = ({
  royaltyOwner,
  royaltyPCT,
  ownerAddress,
  price,
  policy,
  assetName,
  startDatePOSIX,
}: {
  royaltyOwner: string;
  royaltyPCT: string;
  ownerAddress: string;
  price: number;
  policy: string;
  assetName: string;
  startDatePOSIX: string;
}) => {
  /*
    royaltyOwner :: !PubKeyHash,     -- Wallet address for Dropspot
    royaltyPCT :: !Integer,          -- Percentage to be paid to the market place owner
    tradeOwner :: !PubKeyHash,       -- Owners Wallet Public Key hash
    amount :: !Integer,              -- Minimum Trade Amount for Token
    policy :: !BuiltinByteString,    -- Policy of NFT that we are selling
    token :: !BuiltinByteString,     -- NFT that we are selling
    startDate :: !POSIXTime          -- The NFT cannot be purchased before this Date
  */

  console.log('royaltyOwner', royaltyOwner);
  console.log('ownerAddress', ownerAddress);

  const fieldsInner = Loader.Cardano.PlutusList.new();

  // royaltyOwner
  fieldsInner.add(Loader.Cardano.PlutusData.new_bytes(fromHex(royaltyOwner)));

  const royaltyPctPlutus = percentageToContractPCT(
    Number.parseFloat(royaltyPCT)
  );

  // royaltyPCT
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_integer(
      Loader.Cardano.BigInt.from_str(`${royaltyPctPlutus}`)
    )
  );

  // tradeOwner
  fieldsInner.add(Loader.Cardano.PlutusData.new_bytes(fromHex(ownerAddress)));

  // amount
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_integer(
      Loader.Cardano.BigInt.from_str(`${price}`)
    )
  );

  // policy
  fieldsInner.add(Loader.Cardano.PlutusData.new_bytes(fromHex(policy)));

  // token
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_bytes(Buffer.from(assetName, 'utf-8'))
  );

  // Start Date
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_integer(
      Loader.Cardano.BigInt.from_str(startDatePOSIX)
    )
  );

  const datum = Loader.Cardano.PlutusData.new_constr_plutus_data(
    Loader.Cardano.ConstrPlutusData.new(
      Loader.Cardano.BigNum.from_str(DATUM_TYPE.Offer.toString()),
      fieldsInner
    )
  );
  console.log(toHex(datum.to_bytes()));
  return datum;
};

const DATUM_TYPE = {
  Offer: 0,
};

/** Utility Functions **/

function splitStr(str: string, length = 63) {
  let tmp = str;
  const result = [];

  while (tmp.length > length) {
    result.push(tmp.substring(0, length));
    tmp = tmp.substring(length);
  }
  result.push(tmp);
  return result;
}

export function percentageToContractPCT(percent: number) {
  return Math.floor(10 / (percent / 100));
}

export const fromHex = (hex: string) => Buffer.from(hex, 'hex');
export const toHex = (bytes: Uint8Array) => Buffer.from(bytes).toString('hex');
export const fromAscii = (text: string) =>
  Buffer.from(text, 'utf-8').toString('hex');

export const assetsToValue = (
  assets: {
    unit: string;
    quantity: string;
  }[]
) => {
  console.log('In assetsToValue', assets);
  const multiAsset = Loader.Cardano.MultiAsset.new();
  const lovelace = assets.find((asset) => asset.unit === 'lovelace');
  const policies = [
    ...new Set(
      assets
        .filter((asset) => asset.unit !== 'lovelace')
        .map((asset) => asset.unit.slice(0, 56))
    ),
  ];
  policies.forEach((policy) => {
    const policyAssets = assets.filter(
      (asset) => asset.unit.slice(0, 56) === policy
    );
    const assetsValue = Loader.Cardano.Assets.new();
    policyAssets.forEach((asset) => {
      assetsValue.insert(
        Loader.Cardano.AssetName.new(Buffer.from(asset.unit.slice(56), 'hex')),
        Loader.Cardano.BigNum.from_str(asset.quantity)
      );
    });
    multiAsset.insert(
      Loader.Cardano.ScriptHash.from_bytes(Buffer.from(policy, 'hex')),
      assetsValue
    );
  });
  const value = Loader.Cardano.Value.new(
    Loader.Cardano.BigNum.from_str(lovelace ? lovelace.quantity : '0')
  );
  if (assets.length > 1 || !lovelace) value.set_multiasset(multiAsset);
  return value;
};

export const valueToAssets = (value: Value) => {
  const assets = [];
  assets.push({ unit: 'lovelace', quantity: value.coin().to_str() });
  const ma = value.multiasset();
  if (ma) {
    const multiAssets = ma.keys();

    for (let j = 0; j < multiAssets.len(); j++) {
      const policy = multiAssets.get(j);
      const policyAssets = ma.get(policy);
      if (!policyAssets) {
        continue;
      }
      const assetNames = policyAssets.keys();
      for (let k = 0; k < assetNames.len(); k++) {
        const policyAsset = assetNames.get(k);
        const quantity = policyAssets.get(policyAsset);
        const asset = `${toHex(policy.to_bytes())}${Buffer.from(
          policyAsset.name()
        ).toString('utf-8')}`;
        assets.push({
          unit: asset,
          quantity: quantity?.to_str() || '0',
        });
      }
    }
  }
  return assets;
};

// Debugging Functions
// function handleDatum(data: PlutusData) {
//   switch (data.kind()) {
//     case Loader.Cardano.PlutusDataKind.Integer:
//       console.log('Data Int', data.as_integer());
//       break;
//     case Loader.Cardano.PlutusDataKind.Map:
//       console.log('Data Map', data.as_map());
//       const m = data.as_map();
//       if (m) {
//         const keys = m.keys();
//         for (let i = 0; i < keys.len(); i++) {
//           keys.get(i);

//           const data = m.get(keys.get(i));
//         }
//       }
//       break;
//     case Loader.Cardano.PlutusDataKind.List:
//       console.log('Data List', i, data.as_list());
//       break;
//     case Loader.Cardano.PlutusDataKind.Bytes:
//       console.log('Data Bytes', i, data.as_bytes());
//       break;
//     case Loader.Cardano.PlutusDataKind.ConstrPlutusData:
//       console.log('Data', i, data.as_constr_plutus_data());
//       break;
//   }
// }

// function showDatums(datums: PlutusList) {
//   for (let i = 0; i < datums.len(); i++) {
//     const data = datums.get(i);

//     console.log(toHex(data.to_bytes()));
//   }
// }

type ExUnitReturn = {
  result: {
    EvaluationResult: {
      [key: string]: {
        memory: number;
        steps: number;
      };
    };
  };
};

// I need to work out how to tie this back in!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function evaluateTxn(txnCbor: string): Promise<ExUnitReturn> {
  const response = await fetch(
    getAbsoluteURL(`/api/blockfrost/plutus/utils/txs/evaluate`),
    {
      method: 'POST',
      body: txnCbor,
      headers: {
        'Content-Type': 'application/cbor',
      },
    }
  );

  if (!response.ok) {
    console.error(await response.text());
    throw new Error('Unable to talk with blockfrost; check server logs!');
  }

  const data = await response.json();

  if (!isExUnitReturn(data))
    throw new Error('Unable to get ExUnits for Transaction');

  return data;
}

function isExUnitReturn(data: unknown): data is ExUnitReturn {
  return (
    (data as ExUnitReturn).result !== undefined &&
    (data as ExUnitReturn).result.EvaluationResult !== undefined
  );
}

export async function blockfrost<T>(path: string, showErr = true): Promise<T> {
  const response = await fetch(getAbsoluteURL(`/api/blockfrost/${path}`), {
    headers: {
      accept: 'application/json',
    },
  });

  if (!response.ok) {
    showErr && console.error(await response.text());
    throw new Error('Unable to talk with blockfrost; check server logs!');
  }
  const json = await response.json();
  return json as unknown as T;
}

async function getTokenListingDetails(
  utxo: components['schemas']['address_utxo_content'][number],
  policy: string,
  tokenName: string
) {
  if (!utxo.data_hash) throw new Error('Token has been bombed');
  const asset = `${policy}${fromAscii(tokenName)}`;

  try {
    const offer = await getOfferMetadata(utxo.data_hash);
    return {
      price: offer._metadata.price,
      tradeOwner: offer._uid,
    };
  } catch (_) {
    const mintRequests = await getMintRequestForPolicy(policy);
    if (
      mintRequests.length === 0 ||
      !mintRequests[0].assets ||
      mintRequests[0].assets.length === 0
    ) {
      throw new Error('No Minting Requests found');
    }
    if (mintRequests[0].type === 'Redemption') {
      throw new Error('Incorrect Minting type for this process.');
    }

    const token = mintRequests[0].tokens?.find(
      (t) => `${t.policy}${fromAscii(t.tokenName)}` === asset
    );
    const a = mintRequests[0].assets.find((a) => a.assetId === token?.assetId);

    if (!a) {
      throw new Error('Asset not found');
    }
    return {
      price: a.price,
      tradeOwner: mintRequests[0].initialSaleInfo.tradeOwnerAddress,
    };
  }
}

/**
 *
 * @param policy Policy ID
 * @param assetName Asset Name (in UTF8)
 * @param contracts An array of Dropspot Supported Contracts
 * @returns The contracts where this asset is available for Purchase.
 */
export async function getAssetAddresses(
  policy: string,
  assetName: string,
  contracts: Contract[]
) {
  const token = `${policy}${Buffer.from(assetName).toString('hex')}`;

  const addresses = await blockfrost<components['schemas']['asset_addresses']>(
    `plutus/asset/${token}/addresses`
  );

  return contracts.filter((a) =>
    addresses.find((c) => c.address === a.address)
  );
}

export async function getAssetListingDetails(
  contractAddress: string,
  policy: string,
  assetName: string
) {
  const token = `${policy}${Buffer.from(assetName).toString('hex')}`;

  const utxos = await blockfrost<components['schemas']['address_utxo_content']>(
    `plutus/${contractAddress}/utxos/${token}?count=1&page=1`
  );

  if (utxos.length !== 1) throw new Error('Not for sale');

  return await getTokenListingDetails(utxos[0], policy, assetName);
}

type BF_UTXO = components['schemas']['address_utxo_content'][number];
function flattenIfArray(s?: string | string[]): string | undefined {
  if (!s) return undefined;
  return Array.isArray(s) ? s.join('') : s;
}

export async function addressToPaymentKeyHash(addr: string) {
  await Loader.load();
  const address = Loader.Cardano.Address.from_bech32(addr);

  const ea = Loader.Cardano.EnterpriseAddress.from_address(address);

  let hash = ea?.payment_cred().to_keyhash()?.to_bytes();

  if (hash) {
    return toHex(hash);
  }

  const ba = Loader.Cardano.BaseAddress.from_address(address);

  hash = ba?.payment_cred().to_keyhash()?.to_bytes();

  if (hash) {
    return toHex(hash);
  }

  return 'Unknown';
}

/*
lovelacePercentage am p =
if p > 0
then if result < minLovelace then minLovelace else result
else 0 -- Prevent Divide By Zero
where
result = (am * 10) `Plutus.divide` p
*/
const llPct = (amt: number, pct: number) => {
  if (pct === 0) return 0;

  const split = Math.floor((amt * 10) / pct);

  return split < MIN_LOVELACE ? MIN_LOVELACE : split;
};

function disDatumToArray(splitAmount: number, datum?: PlutusList) {
  const arr: DisAmount[] = [];

  if (datum) {
    for (let i = 0; i < datum?.len(); i++) {
      const disb = datum.get(i).as_constr_plutus_data()?.data();

      const address = disb?.get(0).as_bytes();
      const percent = disb?.get(1).as_integer();

      if (!address || !percent) {
        throw new Error('Record not setup right');
      }

      arr.push({
        address: address,
        percent: Number.parseInt(percent?.to_str()),
        amount: llPct(splitAmount, Number.parseInt(percent?.to_str())),
      });
    }
  }

  return arr;
}

export async function isNftOwner(
  wallet: Cardano,
  policy: string,
  assetName: string
) {
  await Loader.load();

  const encAssetName = fromAscii(assetName);

  // getUtxos has params that could be useful.
  const utxos = ((await wallet.getUtxos()) || []).map((utxo) =>
    Loader.Cardano.TransactionUnspentOutput.from_bytes(fromHex(utxo))
  );

  const utxo = utxos.find((utxo) => {
    const multiAsset = utxo.output().amount().multiasset();

    if (multiAsset) {
      const maKeys = multiAsset.keys();

      for (let i = 0; i < maKeys.len(); i++) {
        const maPolicy = toHex(maKeys.get(i).to_bytes());

        if (maPolicy === policy) {
          const asset = multiAsset.get(maKeys.get(i));

          const assetNames = asset?.keys();

          if (assetNames?.len()) {
            for (let j = 0; j < assetNames.len(); j++) {
              const maAssetName = assetNames.get(j);
              if (encAssetName === toHex(maAssetName.name())) {
                return true;
              }
            }
          }
        }
      }
    }
  });

  return !!utxo;
}

import firebase from 'firebase/app';
import 'firebase/auth';
import { ApiError } from 'next/dist/server/api-utils';

export async function updateAssetListing(
  assetId: string,
  price: number | null,
  txHash: string | null,
  marketStatus: 'LISTED' | 'UNLISTED' | 'OFFER'
) {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) return;

  const url = getAbsoluteURL('/api/updateAssetListing');

  const result = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await currentUser.getIdToken()}`,
    },
    body: JSON.stringify({
      assetId,
      price,
      txHash,
      marketStatus,
    }),
  });

  if (!result.ok) {
    console.error(await result.json());
  }
}

/*----------*/
/*----------*/
/*----------*/

async function _getOffer(
  contractAddress: string,
  policy: string,
  tokenName: string
): Promise<TradeUtxo | null> {
  console.log(
    'DropSpotMarket',
    'In getOffer',
    contractAddress,
    policy,
    tokenName
  );
  const asset = `${policy}${fromAscii(tokenName)}`;

  const offerUtxo = await getUtxo(contractAddress, asset, policy, tokenName);

  if (offerUtxo && offerUtxo.length === 1) {
    // Validate the Offer Datum to ensure that for instance it is requesting the minimum amounts etc.

    return {
      policy,
      token: tokenName,
      ...offerUtxo[0],
      lovelace: `${offerUtxo[0].metadata?._metadata?.price || '100000000'}`,
    };
  }

  return null;
}

export async function hexAddressToBech32(addr: string) {
  console.log('In hexAddressToBech32', addr);
  await Loader.load();
  const address = Loader.Cardano.Address.from_hex(addr);

  console.log(
    'Out hexAddressToBech32',
    address.to_bech32(address.network_id() === 0 ? 'addr_test' : 'addr')
  );
  return address.to_bech32(address.network_id() === 0 ? 'addr_test' : 'addr');
}

async function getOnchainMetadata(utxo: BF_UTXO) {
  type DataType = {
    tx_hash: string;
    /** Content of the JSON metadata */
    json_metadata: { [index: string]: string | string[] } | string | string[];
    label: string;
  };
  const metadata = await blockfrost<DataType[]>(
    `/plutus/txns/${utxo.tx_hash}/metadata`
  );

  const m = metadata.filter(
    (m) =>
      m.label === DATUM_LABEL.toString() || m.label === ADDRESS_LABEL.toString()
  );

  // Verify that the metadata is correct format
  return m;
}

async function getUtxo(
  contractAddress: string,
  asset: string,
  policy?: string,
  tokenName?: string
) {
  await Loader.load();
  const utxos = await blockfrost<BF_UTXO[]>(
    `/plutus/${contractAddress}/utxos/${asset}?count=1&page=1`
  );

  return await Promise.all(
    utxos.map(async (utxo) => {
      let metadata: Record<number | '_uid', string> &
        Record<'_metadata', OFFER_DATUM_TYPE>;

      if (utxo.data_hash) {
        try {
          metadata = await getOfferMetadata(utxo.data_hash);
        } catch (err) {
          if (!policy || !tokenName) {
            throw new Error('Unknown UTxO - Datum not held');
          }

          try {
            const onChainMetadata = await getOnchainMetadata(utxo);

            if (onChainMetadata && onChainMetadata.length > 0) {
              const metadataDtm = onChainMetadata.find(
                (m) => m.label === DATUM_LABEL.toString()
              );
              const metadataAddr = onChainMetadata.find(
                (m) => m.label === ADDRESS_LABEL.toString()
              );

              if (!metadataDtm || !metadataAddr) {
                throw new Error(
                  'Unknown Error - Invalid Metadata, need Address and Datum'
                );
              }
              const a = metadataDtm.json_metadata as {
                [index: string]: string | string[];
              };
              const datum = flattenIfArray(a[utxo.output_index]);
              const b = metadataAddr.json_metadata as string | string[];
              const tradeOwner = flattenIfArray(b);

              if (!tradeOwner || !datum)
                throw new Error('No TradeOwner or Datum');

              console.debug('Trade owner', tradeOwner);
              console.debug('datum', datum);

              const ownerAddr = Loader.Cardano.Address.from_bytes(
                fromHex(tradeOwner)
              );
              const ownerBaseAddress = Loader.Cardano.BaseAddress.from_address(
                ownerAddr
              )
                ?.payment_cred()
                .to_keyhash()
                ?.to_bytes();

              if (!ownerBaseAddress)
                throw new Error(
                  'Cannot convert Trade Owner Address to Base Address'
                );

              const pd = Loader.Cardano.PlutusData.from_bytes(fromHex(datum));

              if (
                toHex(Loader.Cardano.hash_plutus_data(pd).to_bytes()) !==
                utxo.data_hash
              ) {
                throw new Error('Onchain Metadata: Datum hash does not match.');
              }

              const cs = pd.as_constr_plutus_data();

              const price = cs?.data().get(3).as_integer()?.to_str();
              const startDatePOSIX = cs?.data().get(6).as_integer()?.to_str();

              if (!price)
                throw new Error(
                  '`fromDatum`: Unable to get price from PlutusData'
                );
              if (!startDatePOSIX)
                throw new Error(
                  '`fromDatum`: Unable to get startDatePOSIX from PlutusData'
                );

              const metadata = {
                [utxo.output_index]: datum,
                _uid: toHex(ownerAddr.to_bytes()),
                _metadata: {
                  ownerAddress: ownerBaseAddress,
                  price: Number.parseInt(price),
                  policy: policy,
                  assetName: tokenName,
                  startDatePOSIX: startDatePOSIX,
                },
              };
              return {
                datum: pd,
                metadata,
                dropspotAddress: DROPSPOT_ADDRESS,
                utxo: Loader.Cardano.TransactionUnspentOutput.new(
                  Loader.Cardano.TransactionInput.new(
                    Loader.Cardano.TransactionHash.from_bytes(
                      fromHex(utxo.tx_hash)
                    ),
                    utxo.output_index
                  ),
                  Loader.Cardano.TransactionOutput.new(
                    Loader.Cardano.Address.from_bech32(contractAddress),
                    assetsToValue(utxo.amount)
                  )
                ),
              };
            }
          } catch (e) {
            console.warn(e);
          }

          const mintRequests = await getMintRequestForPolicy(policy);

          if (
            mintRequests.length === 0 ||
            !mintRequests[0].assets ||
            mintRequests[0].assets.length === 0
          ) {
            throw new Error('No Minting Requests found');
          }

          if (mintRequests[0].type === 'Redemption') {
            throw new Error('Incorrect mint type for this process');
          }

          const token = mintRequests[0].tokens?.find(
            (t) => `${t.policy}${fromAscii(t.tokenName)}` === asset
          );
          const a = mintRequests[0].assets.find(
            (a) => a.assetId === token?.assetId
          );

          if (!a) {
            throw new Error('Asset not found');
          }
          const saleInfo = mintRequests[0].initialSaleInfo;
          console.log('DSM', 'saleInfo', JSON.stringify(saleInfo));
          const price = a.price;

          const ownerAddress = Loader.Cardano.Address.from_bech32(
            saleInfo.tradeOwnerAddress
          );

          const ownerBaseAddress = Loader.Cardano.BaseAddress.from_address(
            ownerAddress
          )
            ?.payment_cred()
            .to_keyhash()
            ?.to_bytes();

          if (!ownerBaseAddress) {
            throw new Error('ownerAddress is not workable');
          }

          const royalties =
            saleInfo.royalties.map((r) => ({
              percent: r.percent,
              address: Loader.Cardano.Address.from_bech32(r.address).to_bytes(),
            })) || [];

          const disbursements =
            saleInfo.split.map((r) => ({
              percent: r.percent,
              address: Loader.Cardano.Address.from_bech32(r.address).to_bytes(),
            })) || [];

          metadata = {
            [utxo.output_index]: toHex(
              OFFER({
                royalties,
                disbursements,
                assetName: tokenName,
                ownerAddress: toHex(ownerBaseAddress),
                policy,
                price: price,
                startDatePOSIX: saleInfo.startDatePOSIX,
              }).to_bytes()
            ),
            _uid: toHex(ownerAddress.to_bytes()),
            _metadata: {
              ownerAddress: ownerBaseAddress,
              price: price,
              policy: policy,
              assetName: tokenName,
              startDatePOSIX: saleInfo.startDatePOSIX,
            },
          };
        }
      } else {
        throw new Error('Unknown UTxO - Datum not held');
      }

      const datumCbor = metadata[utxo.output_index] as string;
      const datum = Loader.Cardano.PlutusData.from_bytes(fromHex(datumCbor));

      if (
        toHex(Loader.Cardano.hash_plutus_data(datum).to_bytes()) !==
        utxo.data_hash
      ) {
        throw new Error('Datum hash does not match.');
      }

      return {
        datum,
        metadata,
        dropspotAddress: DROPSPOT_ADDRESS,
        utxo: Loader.Cardano.TransactionUnspentOutput.new(
          Loader.Cardano.TransactionInput.new(
            Loader.Cardano.TransactionHash.from_bytes(fromHex(utxo.tx_hash)),
            utxo.output_index
          ),
          Loader.Cardano.TransactionOutput.new(
            Loader.Cardano.Address.from_bech32(contractAddress),
            assetsToValue(utxo.amount)
          )
        ),
      };
    })
  );
}

// function redeemerTagKindToText(tag: number) {
//   // 0 ; inputTag "Spend"
//   // / 1 ; mintTag  "Mint"
//   // / 2 ; certTag  "Cert"
//   // / 3 ; wdrlTag  "Reward"
//   switch (tag) {
//     case 0:
//       return 'spend';
//   }
// }

export const getOffer = _getOffer;
