// import * as Cardano from '@emurgo/cardano-serialization-lib-asmjs';
// import type CardanoType from '@emurgo/cardano-serialization-lib-asmjs';

import type CardanoType from '@emurgo/cardano-serialization-lib-browser';
class Loader {
  private _wasm!: typeof CardanoType;
  async load() {
    if (this._wasm) return;
    /**
     * @private
     */
    this._wasm = await import('@emurgo/cardano-serialization-lib-browser');
    // return this._wasm.load();
  }

  get Cardano() {
    return this._wasm;
  }
}

export default new Loader();
