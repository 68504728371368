import Loader from '#lib/Loader';
import { fromHex, toHex } from './DropspotMarket';

export async function validateWalletBech32Address(address: string) {
  await Loader.load();

  try {
    Loader.Cardano.Address.from_bech32(address);
    return true;
  } catch (_) {
    return false;
  }
}

export async function getAddressPKH(address: string) {
  await Loader.load();

  try {
    const a = Loader.Cardano.Address.from_bytes(fromHex(`70${address}`));
    // const a = Loader.Cardano.Address.from_bech32(address);

    return a.to_bech32('addrtest');
  } catch (_) {
    return 'Not able';
  }
}

export function cardanoExplorerURL() {
  return !!process.env.NEXT_PUBLIC_BLOCKFROST_API_TESTNET
    ? 'https://testnet.cardanoscan.io'
    : 'https://cardanoscan.io';
}
